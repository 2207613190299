import { Controller } from "stimulus"
import { formatDistanceToNow } from "date-fns";
import { format } from "date-fns";

const parsedTime = (time) => {
  let parsedTime = Date.parse(time)
  let options = {
    includeSeconds: false,
    addSuffix: true
  }
  return formatDistanceToNow(parsedTime, options)
};

const formattedTime = (time) => {
  let parsedTime = Date.parse(time)

  return format(parsedTime, 'MMM d, yyyy h:mm a')
};

const isElementInContainer = (el, container) => {
  if (typeof jQuery === "function" && el instanceof jQuery) {
    el = el[0];
  }
  if (typeof jQuery === "function" && container instanceof jQuery) {
    container = container[0];
  }

  let containerRectangle = container.getBoundingClientRect();
  let elRectangle = el.getBoundingClientRect();

  return (
      elRectangle.top >= containerRectangle.top &&
      elRectangle.left >= containerRectangle.left &&
      elRectangle.bottom <= containerRectangle.bottom &&  //(window.innerHeight ||// document.documentElement.clientHeight) && /* or
      // $(window).height() */
      elRectangle.right <= containerRectangle.right //(window.innerWidth || document.documentElement.clientWidth) /* or
      // $(window).width() */
  );
}

const throttle = (wait, fn) => {
  let time = Date.now();

  return function() {
    if ((time + wait - Date.now()) < 0) {
      fn();
      time = Date.now();
    }
  }
}

export default class extends Controller {
  static targets = [ "messages", "messageForm", "blankOutgoing", "blankIncoming", "sendMessage" ]

  initialize() {
    // Use javascripts/chat.js from theme to scroll messages inside window
    window.KTAppChat.init();
  }

  connect() {
    console.log("components/chat_panel connected");

    const token = this.element.getAttribute("data-token");
    const convo_sid = this.element.getAttribute("data-conversation");

    this.textAreaHeight = 38;

    this.chatBoxSpinner = $("#chat-box-spinner");
    this.chatBoxSpinner.html(spinner());

    this.chatterOnline = $("#chatter-online");
    this.chatterOffline = $("#chatter-offline");

    this.lastReadMessageIndex = 0;

    // array of messages arranged newest first
    this.conversationMessages = [];

    if (window.twilioClient) {
      this.clientActions(window.twilioClient, convo_sid);
    } else {
      Twilio.Conversations.Client.create(token).then(client => {
        window.twilioClient = client;
        this.clientActions(window.twilioClient, convo_sid);
      });
    }
  }

  clientActions(client, convo_sid) {
    this.twilioClient = client;
    let that = this;

    client.getConversationBySid(convo_sid).then(currentConversation => {
      this.currentConversation = currentConversation;
      this.lastReadMessageIndex = currentConversation.channelState.lastReadMessageIndex;
      window.lastReadMessageIndex = this.lastReadMessageIndex;
      window.typingParticipants = [];

      this.monitorOnlineStatus(currentConversation);

      // fetch 1000 most-recent messages (default fetch is 30)
      currentConversation.getMessages(1000).then(response => {
        this.chatBoxSpinner.empty();
        let messages = response.items;

        messages.forEach(message => {
          this.insertMessage(message);
        })

        this.scrollToUnread(currentConversation.sid);

        this.startUnreadTimer(1000, currentConversation.sid);
      });

      // intercept the keydown event
      $(`#chat-box-${convo_sid}`).on('keydown', function(e) {
        currentConversation.typing();
      });

      //set up the listener for the typing started Conversation event
      currentConversation.on('typingStarted', function(participant) {
        that.updateTypingIndicator(participant, true, currentConversation.sid);
      });

      //set  the listener for the typing ended Conversation event
      currentConversation.on('typingEnded', function(participant) {
        that.updateTypingIndicator(participant, false, currentConversation.sid);
      });

      this.monitorMessagesRead(currentConversation);

      currentConversation.on("messageAdded", (message) => {
        this.insertMessage(message);
        this.markMessageRead(currentConversation, message.index);
      });

      currentConversation.on("messageUpdated", ({message, updateReasons}) => {
        let messageElement = document.getElementById(`chat_message_${message.state.index}`);
        if (updateReasons.includes("body")) {
          messageElement.children[messageElement.children.length - 1].innerHTML = message.body;
        } else if (document.contains(messageElement)) {
          messageElement.remove();
        }
      });

      currentConversation.on("participantJoined", (participant) => {
        this.addProfileImages(currentConversation);
      });
    });

    client.on("tokenAboutToExpire", function() {
      setupAjax();
      $.ajax({
        url: `/conversations/${convo_sid}`,
        method: "PUT",
        dataType: "json",
        success: function (data) {
          client.updateToken(data.token);
        }, error: function () {
          that.sendMessageTarget.disabled = false;
        },
      });
    });

    client.on('tokenExpired', function() {
      console.log("Token expired panel");
      setupAjax();
      $.ajax({
        url: `/conversations/${convo_sid}`,
        method: "PUT",
        dataType: "json",
        success: function (data) {
          client.updateToken(data.token);
        }, error: function () {
          that.sendMessageTarget.disabled = true;
          alert("Your chat token has expired. Please refresh the page to continue chatting.");
        },
      });
    }); 
  }

  markMessageRead(conversation, messageIndex) {
    let controller = this;
    conversation.advanceLastReadMessageIndex(messageIndex).then(success => {
    }, failure => {
      console.log(failure);
    });
  }

  monitorOnlineStatus(conversation) {
    conversation.participants.forEach(participant => {
      participant.getUser().then(user => {
        if (twilioClient.user === user) { return }

        user.on("updated", event => {
          this.toggleOnlineStatus(event.user)
        })
        this.toggleOnlineStatus(user);
      })
    })
  }

  toggleOnlineStatus(user) {
    if (user.isOnline === true) {
      this.chatterOffline.css('display', 'none')
      this.chatterOnline.css('display', '')
    } else {
      this.chatterOnline.css('display', 'none')
      this.chatterOffline.css('display', '')
    }
  }

  sendMessage(event) {
    event.stopPropagation();
    let message = this.messageFormTarget.value;
    if (message.length < 1) {
      return
    }

    this.sendMessageTarget.disabled = true;
    this.messageFormTarget.disabled = true;

    let that = this;
    setupAjax();
    $.ajax({
      url: `/conversations/${that.currentConversation.sid}/conversation_messages`,
      method: "POST",
      data: {
        message: message
      },
      success: function (data) {
        that.clearMessageArea(that.messageFormTarget);
        that.scrollToUnread(that.currentConversation.sid);
        that.sendMessageTarget.disabled = false;
      },
      error: function (data) {
        that.messageFormTarget.disabled = false;
        that.sendMessageTarget.disabled = false;
        if (typeof data.responseJSON !== 'undefined' && data.responseJSON.flaggedContent) {
          $("#contentWarningModal").modal("show");
          $("#flaggedMessageContent").html(data.responseJSON.flaggedContent);
        } else {
          alert("Unable to send the message. This could be due to a expired session, network issue, or your message is too long (32,000 characters).");
        }
      },
    });
  }

  insertMessage(message) {
    let currentAuthor = this.element.getAttribute("data-current-author");
    let authorName = this.element.getAttribute("data-author-name");

    let messageTemplate;

    if (message.attributes.hidden === true) {
      return;
    }

    if ((message.author === currentAuthor) || (message.author === currentAuthor && message.attributes.role === 'Admin')) {
      // outgoing message
      messageTemplate = this.blankOutgoingTarget.innerHTML;
    } else {
      // incoming message
      messageTemplate = this.blankIncomingTarget.innerHTML;
    }

    let avatar = JSON.parse(this.sendMessageTarget.dataset.avatars)[message.author];
  
    if (!!avatar) {
      let regex = /src="([^"]*)"/g;
      messageTemplate = messageTemplate.replace(regex, `src="${avatar}"`);
    }

    if (message.attributes.name == 'System Message') {
      messageTemplate = messageTemplate.replace("%MESSAGE_BODY%", `<span class='system-message-color'>${message.body}</span>`);
    } else {
      messageTemplate = messageTemplate.replace("%MESSAGE_BODY%", message.body);
    }

    messageTemplate = messageTemplate.replace('%MESSAGE_TIME%', parsedTime(message.dateCreated));
    messageTemplate = messageTemplate.replace('%FORMATTED_TIME%', formattedTime(message.dateCreated));
    messageTemplate = messageTemplate.replace('%MESSAGE_INDEX%', message.index);
    messageTemplate = messageTemplate.replace('%SENDER_NAME%', message.attributes.name);
    messageTemplate = messageTemplate.replace('%SENDER_LINK%', message.attributes.link);
    messageTemplate = messageTemplate.replace("%MESSAGE_SID%", message.sid);
    messageTemplate = messageTemplate.replace("%SENDER_ROLE%", message.attributes.style);

    this.messagesTarget.insertAdjacentHTML('beforeend', messageTemplate);
    this.conversationMessages.unshift(message);
  }

  monitorMessagesRead(conversation) {
    let container = $(`#chat-panel-message-container-${conversation.sid}`);
    if (typeof jQuery === "function" && container instanceof jQuery) {
      container = container[0];
    }

    let isScrolling;
    container.addEventListener("scroll", function (evt) {
      clearTimeout(isScrolling);

      isScrolling = setTimeout(() => {
        this.checkMessagesVisible(evt);
      }, 100);
    }.bind(this));
  }

  checkMessagesVisible(evt) {
    let lastReadMessageIndex = this.lastReadMessageIndex;
    let messages = this.conversationMessages;

    messages.every(message => {
      let msg = $(`#chat_message_${message.index}`);
      let visible = isElementInContainer(msg, evt.target)

      if (visible) {
        this.markMessageRead(this.currentConversation, message.index);
        return false;
      }
      return true;
    });
  }

  // `behavior: smooth` not compatible with PerfectScrollbar (used by chat.js)
  scrollToUnread(conversationId) {
    if (document.getElementById(`messages-${conversationId}`)) {
      document.getElementById(`chat-panel-message-container-${conversationId}`).scrollTop = document.getElementById(`messages-${conversationId}`).scrollHeight;
    }
  }

  clearMessageArea(element) {
    element.value = "";
    element.disabled = false;
    element.style.height = `${this.textAreaHeight}px`;
  }

  startUnreadTimer(timer, sid) {
    let context = this;
    setTimeout(() => {
      context.scrollToUnread(sid)
    }, timer)
  }

  hideMessage(event) {
    let message = event.currentTarget.dataset.message;
    let conversation = this.element.getAttribute("data-conversation");
    let messageElement = event.currentTarget.parentElement.parentElement;

    if (confirm("Are you sure you want to hide this message? This cannot be undone.")) {
      setupAjax();
      $.ajax({
        url: `/customer_conversations/${conversation}/hide_message`,
        method: "PUT",
        data: {
          id: message
        },
        dataType: "json",
        success: function (data) {
          messageElement.innerHTML = '';
        }
      });
    }
  }

  addProfileImages(conversation) {
    let that = this;
    setupAjax();
    $.ajax({
      url: `/conversations/${conversation.sid}/user_avatars`,
      method: "GET",
      data: {
        conversation_sid: conversation.sid
      },
      success: function (data) {
        that.sendMessageTarget.dataset.avatars = data.avatars;
      }
    });
  }

  updateTypingIndicator(participant, typing, convo_sid) {
    let typingIndicator = $(`#typing-indicator-${convo_sid}`);
    let indicatorSpace = $(`#indicator-space-${convo_sid}`);

    if (typing) {
      if (window.typingParticipants.includes(participant.sid) === false && participant.identity !== this.element.getAttribute("data-current-author")) {
        window.typingParticipants.push(participant.sid);
      }

      if (window.typingParticipants.length === 0) { return; }

      if (window.typingParticipants.length === 1) {
        if (participant.identity === this.element.getAttribute("data-current-author")) { return; }

        let name = participant.attributes.name;
        if (name === undefined) { name = "A User"; }
        typingIndicator.html(`<b>${name}</b>  is typing...`);
      } else {
        typingIndicator.html('<b>Multiple people</b> are typing...');
      }
      indicatorSpace.removeClass('invisible');
    } else {
      var index = window.typingParticipants.indexOf(participant.sid);
      if (index !== -1) {
        window.typingParticipants.splice(index, 1);
      }
      indicatorSpace.addClass('invisible');
    }
  }
}
